import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiBlog from "@/api/ApiBlog";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import ApiAdmin from "@/api/ApiAdmin";
import dayjs from "dayjs";
import CKEditorConfig from "@/data/CKeditor/config";
let BlogCreate = class BlogCreate extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/admin/blog",
                text: "CMS",
            },
            {
                text: "Blog",
                disabled: true,
            },
        ];
        this.HasLoading = false;
        this.editor = ClassicEditor;
        this.editorConfig = CKEditorConfig.editorConfig(ApiEnter.CurrentSessionUUID);
        this.CardDesignData = ["Blue", "Orange", "White", "With image"];
        this.isOpenDate = false;
        this.PostPublish = true;
        this.PostName = "";
        this.PostDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
        this.PostImage1 = null;
        this.PostImage2 = null;
        this.PostCardDesign = "";
        this.PostContent = "";
        this.PostSeoDescription = "";
        this.PostSeoKeywords = "";
        this.PostSeoUrl = "";
    }
    get computedDateFormatted() {
        return dayjs.tz(this.PostDate, "Europe/Rome").format("DD.MM.YYYY");
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    GenerateSeoUrl() {
        let url = this.Translit(this.PostName.replace(/[\s]+/gi, "-"));
        this.PostSeoUrl = url.replace(/[^0-9a-z_-]+/gi, "").toLowerCase();
        // this.PostSeoUrl = this.PostName.replace(/[. ,:-=&+#$?|%@!^(){}'*]+/g, "-").toLowerCase()
    }
    ValidateSeoUrl() {
        this.PostSeoUrl = this.Translit(this.PostSeoUrl.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            const file_name1 = this.PostImage1 !== null ? await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.PostImage1) : "";
            const file_name2 = this.PostImage2 !== null ? await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.PostImage2) : "";
            const post_uuid = await ApiBlog.CreateBlog(ApiEnter.CurrentSessionUUID, this.PostName, dayjs.tz(this.PostDate, "Europe/Rome").format("YYYY-MM-DD"), this.PostPublish ? 1 : 0, file_name1, file_name2, this.PostCardDesign, this.PostContent, this.PostSeoDescription, this.PostSeoKeywords, this.PostSeoUrl);
            if (post_uuid == undefined || post_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error") + post_uuid,
                    icon: "error",
                });
                return;
            }
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => {
                this.$forceUpdate();
                this.$router.push(`/admin/blog`);
            });
        }
        this.HasLoading = false;
    }
};
BlogCreate = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, CKEditor },
    })
], BlogCreate);
export default BlogCreate;
